'use strict';

var mobileViewport = 992;
var isSafari = navigator.userAgent.indexOf("Safari") > -1;
var isChrome   = navigator.userAgent.indexOf('Chrome') > -1;
if((isSafari) && (isChrome)) {
    isSafari = false;
}

var testimonialCarousel;

$(document).ready(function(){

    afternavHeight();
    activeBookmark();

    BSTabsActions();




});






        /*==== testimonial-carousel =====*/
        if ($(testimonialCarousel).length) {
           
        }






function afternavHeight() {
    $('.afternav-height').each(function(){
        var $mainNav = $('#main-nav'),
            mainNavHeight = $mainNav.height(),
            height = $(window).height() - mainNavHeight;

        $(this).css('height', height);
    });
}


function activeBookmark() {
    $('.theme-search-results-item-bookmark').each(function(index, el){
        $(el).on('click', function(e){
            e.preventDefault();
            $(this).toggleClass('active');
        });
    });
}








function BSTabsActions() {
    $('#sticky-tab').on('shown.bs.tab', function (e) {
      $('.sticky-tab-col').stick_in_parent({
            parent: $('#sticky-tab-parent')
        });
    });


    $('#slideTabs a').click(function (e) {
      e.preventDefault();
      $(this).tab('show');
      var control = $(this).attr('aria-controls')
      var active = $('#slideTabsSlides').find('.active')[0];
      var target = $('#slideTabsSlides').find("[data-tab='" + control + "']")[0];
      if(active !== target) {
        $(active).removeClass('active');
        $(target).addClass('active');
      }
    });
}








$('.mobile-picker').each(function(i, item){
    if(!isSafari) {
        $(item).attr('type', 'text');
        $(item).val($(item).attr('value'));
        $(item).on('focus', function(){
            $(item).attr('type', 'date');
        });
        $(item).on('blur', function(){
            $(item).attr('type', 'text');
        });
    }
});


const getCookie = (name) => {
    const value = " " + document.cookie;
    console.log("value", `==${value}==`);
    const parts = value.split(" " + name + "=");
    return parts.length < 2 ? undefined : parts.pop().split(";").shift();
  };
  
  const setCookie = function (name, value, expiryDays, domain, path, secure) {
    const exdate = new Date();
    exdate.setHours(
      exdate.getHours() +
        (typeof expiryDays !== "number" ? 365 : expiryDays) * 24
    );
    document.cookie =
      name +
      "=" +
      value +
      ";expires=" +
      exdate.toUTCString() +
      ";path=" +
      (path || "/") +
      (domain ? ";domain=" + domain : "") +
      (secure ? ";secure" : "");
  };


  (() => {
    const cookiesBanner = document.querySelector(".cookies-eu-banner");
    const cookiesBannerButton =  $('.cookie-button');
  


    // $(".cookie-button").click(function(){
    //     cookiesBanner.remove();
    // }); 

    $(document).on('click','#cookie-button',function(){
        // cookiesBanner.remove();
        $(".cookies-eu-banner").hide();
});
  })();


  const cookiesBanner = $(".cookies-eu-banner");
  console.log(cookiesBanner);

    const cookieName = "cookiesBanner";
    const hasCookie = getCookie(cookieName);

    if (!hasCookie) {
    // cookiesBanner.classList.remove("hidden");
    $(".cookies-eu-banner").removeClass("hidden");

    }


    $(".cookie-button").click(function(){
        alert("button");
        // setCookie(cookieName, "closed");
        // cookiesBanner.remove();
    }); 

//Use this code if button is appended in the DOM
$(document).on('click','#cookie-button',function(){
        setCookie(cookieName, "closed");
        // cookiesBanner.remove();
        $(".cookies-eu-banner").hide();
});